import { Injectable } from '@angular/core';
import { BackendRequestService } from './request/backendRequest.service';
import { MaterialContainer } from '../model/materialContainer';
import { Workflow } from '../model/workflow';
import { WorkflowStepType } from '../model/workflowStepType';
import { WorkflowStep } from '../model/workflowStep';
import { MaterialService } from './material.service';
import { Material } from '../model/material';
import { AuthService } from './auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';


// Service to provide all current workflows of the user
@Injectable({
    providedIn: 'root',
})
export class WorkflowService {

    // Data
    public data: MaterialContainer[] = [];

    // Changed
    private _changed: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    get changed(): Observable<void> {
        return this._changed.asObservable();
    }

    // Workflow write permissions
    public workflowWritePermissions: {
        [key: number]: string[]
    } = {};


    // Constructor
    constructor(private backend: BackendRequestService, private auth: AuthService) {
        this.auth.changed.subscribe(x => this.Load());
    }


    // Refresh the data from the backend
    public async Load() {

        // Load workflows
        this.data = [];
        this.workflowWritePermissions = {};
        if (this.auth.IsAuthenticated()) {
            this.data = await this.backend.Get<MaterialContainer[]>('Workflow/Get');
            this.data.forEach(x => x.workflow.currentStep = Workflow.prototype.currentStep);
            
            for (let item in WorkflowStepType) {
                if (!isNaN(Number(item))) {
                    this.workflowWritePermissions[+item] = await this.backend.Get<string[]>('Workflow/GetPropertiesWithWritePermission', { 'stepType': item });
                }
            }
        }
        this._changed.next(null);
    }


    // Update a workflow
    public async Update(materialContainer: MaterialContainer, workflowStep: WorkflowStep) {

        // Update and reload
        materialContainer = await this.backend.Post<MaterialContainer>('Workflow/UpdateWorkflow', workflowStep, { 'materialId': materialContainer.id });
        await this.Load();
    }


    // Set a workflow back to the previous state (with change of material)
    public async Previous(materialContainer: MaterialContainer, material: Material) {

        // Only possible if material container is valid
        if (materialContainer) {

            // Create workflow step
            let workflowStep = {
                entitledApprovers: [materialContainer.workflow.currentStep().creator],
                stepType: materialContainer.workflow.currentStep().stepType - 1,
                material: material
            } as WorkflowStep;

            // Update and reload
            materialContainer = await this.backend.Post<MaterialContainer>('Workflow/UpdateWorkflow', workflowStep, { 'materialId': materialContainer.id });
            await this.Load();
        }
    }
    // Get data from the backend
    public async GetWorkflowByMaterialId(materialId: string) : Promise<string[]> {
        return this.backend.Get<string[]>('Workflow/GetWorkflowByMaterialId',  { 'materialId': materialId });
    }
}