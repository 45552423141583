import { Component } from '@angular/core';
import { WorkflowService } from 'app/services/workflow.service';
import { AuthService } from 'app/services/auth/auth.service';
import { UserService } from 'app/services/auth/user.service';
import { BackendRequestService } from 'app/services/request/backendRequest.service';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { TranslateService } from '@ngx-translate/core';
import { VersionService } from 'app/services/version.service';
import { formatDate } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PorscheTools } from 'app/common/PorscheToolsStore';
import { MaterialService } from 'app/services/material.service';
import { HttpClient } from "@angular/common/http";




@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent {

    public lenghtOfImportsForCurrentUser: number;

    // Constructor
    constructor(
        private materialService: MaterialService,
        public auth: AuthService,
        public workflow: WorkflowService,
        public user: UserService,
        private backend: BackendRequestService,
        public translateService: TranslateService,
        public versionService: VersionService,
        private dialog: MatDialog) {

        this.materialService.lenghtOfImportsForCurrentUserChanged.subscribe({
            next: (val) => {
                this.lenghtOfImportsForCurrentUser = val;
            }
        });
    }

    ngOnInit() { }

    // Export latest revision
    public async onLatestRevisionExport() {
        let b64String: string = await this.backend.Get<string>('Export/ApprovedLatestMaterials', { 'language': this.translateService.currentLang });
        saveAs(base64StringToBlob(b64String), 'Export_' + 'latestRevisions_' + formatDate(new Date(), 'yyyy-MM-dd_HH:mm', 'en-US') + '.xlsx')
    }

    // Export all revisions
    public async onAllRevisionsExport() {
        let b64String: string = await this.backend.Get<string>('Export/ApprovedMaterials', { 'language': this.translateService.currentLang });
        saveAs(base64StringToBlob(b64String), 'Export_' + 'allRevisions_' + formatDate(new Date(), 'yyyy-MM-dd_HH:mm', 'en-US') + '.xlsx')
    }

    // Export filteredMaterials
    public async onFilterExportAsync() {
        let oResponseExcel = await this.materialService.GetExcelByFilter();
        saveAs(base64StringToBlob(oResponseExcel), 'Export_' + 'filteredList_' + formatDate(new Date(), 'yyyy-MM-dd_HH:mm', 'en-US') + '.xlsx')
    }

    public async onManageLicense() {
        this.dialog.open(OSOLicenseDialog);
    }

    private logout() {
        this.auth.LogoutAsync();
    }

    async logoutDialog() {
        let dialogRef = this.dialog.open(LogoutDialog);
        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                await this.logout();
            }
        });
    }
}

@Component({
    selector: 'app-header-dialog',
    templateUrl: './header-dialog.component.html'
})
export class LogoutDialog {
    constructor(private dialogRef: MatDialogRef<LogoutDialog>) {
    }

    click(data) {
        this.dialogRef.close(data);
    }
}

@Component({
    selector: 'app-oso-license-dialog',
    templateUrl: './oso-license-dialog.component.html'
})
export class OSOLicenseDialog {
    content: any;

    constructor(private dialogRef: MatDialogRef<OSOLicenseDialog>, private httpClient: HttpClient) {
        this.httpClient.get('./assets/files/osoLicense.txt', { responseType: 'text' }).subscribe(data => {
            this.content = data;
        });
    }
}