import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MaterialDto } from "../dto/MaterialDto";
import { Material } from "./../model/material";
import { BackendRequestService } from "./request/backendRequest.service";
import { PorscheTools } from "app/common/PorscheToolsStore";
import { UserService } from "./auth/user.service";
import { ExportDto } from "../dto/ExportDto";
import { MaterialFilterOptions } from "../model/materialFilterOptions";
import { BehaviorSubject, Observable } from 'rxjs';

// Service to provide all approved materials
@Injectable({
    providedIn: 'root',
})
export class MaterialService {

    // Length of the data
    public length: number;

    public currentMaterialFilterOptions: MaterialFilterOptions;

    private _lenghtOfImportsForCurrentUser: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    get lenghtOfImportsForCurrentUserChanged(): Observable<number> {
        return this._lenghtOfImportsForCurrentUser.asObservable();
    }

    // Constructor
    constructor(private backend: BackendRequestService, private translateService: TranslateService, public user: UserService) {
        this.Load();
        this.user.changed.subscribe(x => this.GetImportApprovalListForCurrentUserAsync(true));
    }

    public async GetByIdAndRevision(id: string, revision: number): Promise<Material> {
        return await this.backend.Get<Material>('Material/GetByIdAndRevision', { 'id': id, 'revision': revision.toString() });
    }

    public async Get(options: MaterialFilterOptions): Promise<Material[]> {
        this.currentMaterialFilterOptions = options;
        let response = await this.backend.Post<Material[]>(
            'Material/GetLatestRevisionWithFilter',
            options.body,
            {
                'sortAttribute': options.sortAttribute,
                'sortAsc': options.sortAsc.toString(),
                'skip': options.skip.toString(),
                'limit': options.limit.toString(),
                'lang': this.translateService.currentLang
            });

        return response;
    }

    public async GetExcelByFilter(): Promise<string> {
        if (PorscheTools.isNullOrUndefined(this.currentMaterialFilterOptions)) {
            return null;
        }
        var responseString = await this.backend.Post<string>(
            'Export/FilteredMaterials',
            this.currentMaterialFilterOptions.body,
            {
                'sortAttribute': this.currentMaterialFilterOptions.sortAttribute.toString(),
                'sortAsc': this.currentMaterialFilterOptions.sortAsc.toString(),
                'skip': this.currentMaterialFilterOptions.skip.toString(),
                'limit': this.currentMaterialFilterOptions.limit.toString(),
                'lang': this.translateService.currentLang
            });
        const oResponse = JSON.parse(responseString) as ExportDto;

        return oResponse.excel;
    }

    // Load
    public async Load() {
        this.length = await this.backend.Get<number>('Material/GetReadyCount');
    }
    public async LoadAsync(options: MaterialFilterOptions){
        let response = await this.backend.Post<number>(
            'Material/GetFilteredCount',
            options.body);

        return response;
    }

    public async ImportMaterialsAsync(fileContent: string, fileName: string): Promise<MaterialDto> {
        const o = new MaterialDto();
        // o.FileContent = fileContent;
        o.language = this.translateService.currentLang
        o.fileName = fileName;
        o.userId = this.user.currentUser.id;
        //o.approverId = approverId;
        const requestJson = PorscheTools.encodeHTML(JSON.stringify(o));

        let response: string = await this.backend.Post<string>("Import/ImportExcel",
            JSON.stringify(fileContent),
            {
                "requestJson": requestJson
            }
        );

        const oResponse = JSON.parse(response) as MaterialDto;

        if (!PorscheTools.stringIsNullOrEmpty(oResponse.resultErrorMessage)) {
            alert(oResponse.resultErrorMessage);
        }

        return oResponse;
    }

    public async GetImportFileStateAsync(ImportFileStateId: string): Promise<MaterialDto> {
        const o = new MaterialDto();
        o.importFileStateId = ImportFileStateId;
        const requestJson = PorscheTools.encodeHTML(JSON.stringify(o));
        let response: string = await this.backend.Get<string>('Import/GetImportFileState',
            {
                'requestJson': requestJson
            }
        );
        const oResponse = JSON.parse(response) as MaterialDto;

        if (!PorscheTools.stringIsNullOrEmpty(oResponse.resultErrorMessage)) {
            alert(oResponse.resultErrorMessage);
        }

        return oResponse;
    }

    public async GetImportApprovalListForCurrentUserAsync(loadOpenList: boolean) {
        const o = new MaterialDto();
        o.userId = this.user.currentUser?.id;
        o.loadOpenList = loadOpenList;

        const requestJson = PorscheTools.encodeHTML(JSON.stringify(o));
        let response: string = await this.backend.Get<string>('Import/GetImportApprovalList',
            {
                'requestJson': requestJson
            }
        );
        const oResponse = JSON.parse(response) as MaterialDto;

        if (!PorscheTools.stringIsNullOrEmpty(oResponse.resultErrorMessage)) {
            alert(oResponse.resultErrorMessage);
        }
        if (loadOpenList){
            this._lenghtOfImportsForCurrentUser.next(oResponse?.importApprovalList.length);
        }

        return oResponse;
    }

    public async UpdateImportWorkflowAsync(ApproverId: string, ImportFileStateId: string, IsAborted: boolean): Promise<MaterialDto> {
        const o = new MaterialDto();
        o.approverId = ApproverId;
        o.importFileStateId = ImportFileStateId;
        o.isAborted = IsAborted;
        o.userId = this.user.currentUser.id;

        const requestJson = PorscheTools.encodeHTML(JSON.stringify(o));
        let response: string = await this.backend.Post<string>("Import/UpdateImportWorkflow",
            null,
            {
                "requestJson": requestJson
            }
        );
        const oResponse = JSON.parse(response) as MaterialDto;

        if (!PorscheTools.stringIsNullOrEmpty(oResponse.resultErrorMessage)) {
            alert(oResponse.resultErrorMessage);
        }

        return oResponse;
    }
}
